<template>
  <div>
    <ui-page-hero title="Reservations" />

    <section class="section">
      <MonthYearSelector
        @monthYearSelected="monthYearSelected"
        :isLoadingLeft="isLoadingLeft"
        :isLoadingRight="isLoadingRight"
        :showMonthSelector="false"
        :month="reservationsPageState.month"
        :year="reservationsPageState.year"
      />

      <section class="section is-small">
        <div class="is-hidden-print actionBar has-padding">
          <div class="level is-mobile">
            <div class="level-left"></div>
            <div class="level-right">
              <a
                @click="exportReservations"
                class="has-icon"
                :class="{ 'is-loading': isLoadingCsv }"
              >
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fas', 'file-download']" />
                </span>
                <span>{{
                  $t('Components.Reservation.ReservationDetail.Icon_Download')
                }}</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <div class="navbar-menu">
        <div class="navbar-start">
          <span
            class="select is-small"
            :class="{ 'is-loading': isLoadingLocations }"
          >
            <select
              @change="searchReservations"
              v-model="reservationsPageState.locationId"
            >
              <option
                value="0"
                :selected="reservationsPageState.locationId === 0"
              >
                All locations
              </option>
              <option
                v-for="(location, index) in locations"
                :key="index"
                :value="location.Id"
                :selected="reservationsPageState.locationId === location.Id"
              >
                {{ location.Name }}
              </option>
            </select>
          </span>
          <span
            class="select is-small has-margin-left"
            :class="{ 'is-loading': isLoadingLocations }"
          >
            <select
              @change="searchReservations"
              v-model="reservationsPageState.statusId"
            >
              <option
                v-for="statusId in statusIds"
                :key="statusId"
                :value="statusId"
                :selected="reservationsPageState.statusId === statusId"
              >
                {{ getStatusName(statusId) }}
              </option>
            </select>
          </span>
        </div>

        <div class="navbar-end">
          <div class="field has-addons">
            <input
              type="text"
              v-model="reservationsPageState.searchTerm"
              v-on:keyup.13="searchReservations"
              class="input is-small"
              placeholder="Search reservations"
            />
            <button
              class="button is-primary is-small"
              :class="{ 'is-loading': isSearching }"
              @click="searchReservations"
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>

      <table class="table is-fullwidth is-striped has-margin-top">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Status</th>
            <th>Location</th>
            <th>Type</th>
            <th>Date</th>
            <th>Start</th>
            <th>End</th>
            <th class="has-text-right">Seats</th>
            <th class="has-text-right">Revenue</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <transition-group
          name="staggered-fade"
          v-bind:css="false"
          v-on:before-enter="beforeEnter"
          v-on:enter="enter"
          tag="tbody"
        >
          <tr
            v-for="(reservation, index) in reservationsPageState.reservations"
            :key="'tr_' + index"
            :data-index="index"
          >
            <td>
              <a @click="goToReservation(reservation)">#{{ reservation.Id }}</a>
            </td>
            <td>{{ reservation.ReservationName }}</td>
            <td>{{ getStatusName(reservation.StatusId) }}</td>
            <td>{{ reservation.LocationName }}</td>
            <td>
              {{ reservation.MeetingTypeId | getMeetingtypeAbbreviation }}
            </td>
            <td>
              {{
                reservation.StartDate
                  | parseIsoDateStringToDate
                  | dateObjectIsoDateString
              }}
            </td>
            <td>{{ reservation.StartMinutes | minutesToTime }}</td>
            <td>{{ reservation.EndMinutes | minutesToTime }}</td>
            <td class="has-text-right">{{ reservation.TotalSeats }}</td>
            <td class="has-text-right">
              <span>{{
                reservation.TotalExcl | toCurrency('en', reservation.Currency)
              }}</span>
            </td>
            <td>
              <p class="control">
                <a @click="goToReservation(reservation)" class="is-small">
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'share']" />
                  </span>
                </a>
              </p>
            </td>
          </tr>
        </transition-group>
      </table>

      <transition name="fade" mode="out-in">
        <div v-if="isSearching">
          <ui-loader />
        </div>
      </transition>

      <Pagination
        v-if="
          (!isSearching && reservationsPageState.morePages > 0) ||
          reservationsPageState.page > 1
        "
        :currentPage="reservationsPageState.page"
        :morePages="reservationsPageState.morePages"
        @pageSelected="pageSelected"
      />
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import locationProvider from '@/providers/location'
import reservationProvider from '@/providers/reservation'

const MonthYearSelector = () => import('@/components/UI/MonthYearSelector')
const Pagination = () => import('@/components/UI/Pagination')

export default {
  components: {
    MonthYearSelector,
    Pagination,
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isLoadingLocations: false,
      isLoadingLeft: false,
      isLoadingRight: false,
      isLoadingCsv: false,
      isSearching: false,
      meetingtypeIds: [0, 1, 2, 3],
      statusIds: [-1, 0, 1, 2, 3],
      locations: [],
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservationsPageState']),

    hasSearchInput() {
      return this.reservationsPageState.searchTerm.length > 0
    },
  },

  watch: {},

  created() {
    this.getLocations()
    this.getReservations()
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservationsPageState']),

    getReservations() {
      let self = this
      let profileId = 0

      if (!self.reservationsPageState.reachedEnd) {
        self.isSearching = true

        reservationProvider.methods
          .getReservations(
            self.reservationsPageState.searchTerm,
            this.channelId,
            self.reservationsPageState.locationId,
            profileId,
            self.reservationsPageState.meetingtypeId,
            self.reservationsPageState.month,
            self.reservationsPageState.year,
            self.reservationsPageState.statusId,
            self.reservationsPageState.page,
            self.reservationsPageState.itemsPerPage
          )
          .then((response) => {
            self.isSearching = false

            if (response.data.MorePages === 0) {
              self.reservationsPageState.reachedEnd = true
            }

            self.reservationsPageState.morePages = response.data.MorePages
            self.reservationsPageState.reservations = response.data.Results

            self.setReservationsPageState(self.reservationsPageState)
          })
          .finally(() => {
            self.isSearching = false
            self.isLoadingLeft = false
            self.isLoadingRight = false
          })
      }
    },

    pageSelected(page) {
      let self = this
      self.reservationsPageState.reservations = []
      this.reservationsPageState.bottom = false
      self.reservationsPageState.reachedEnd = false
      self.reservationsPageState.page = page

      self.getReservations()
    },

    searchReservations() {
      this.reservationsPageState.page = 1
      this.reservationsPageState.bottom = false
      this.reservationsPageState.reachedEnd = false
      this.reservationsPageState.reservations = []

      this.setReservationsPageState(this.reservationsPageState)

      this.getReservations()
    },

    monthYearSelected(data) {
      let self = this

      self.reservationsPageState.month = data.month
      self.reservationsPageState.year = data.year

      self.isLoadingLeft = data.direction === 'left'
      self.isLoadingRight = data.direction === 'right'

      this.newInvoiceCounter = 0
      this.noInvoiceLocations = []
      this.showProgressBar = false
      this.totalLocationWithoutInvoice = 0

      this.setReservationsPageState(this.reservationsPageState)

      this.searchReservations()
    },

    exportReservations() {
      let self = this
      let profileId = 0

      if (!self.isLoadingCsv) {
        self.isLoadingCsv = true

        reservationProvider.methods
          .getReservationsCsv(
            self.reservationsPageState.searchTerm,
            this.channelId,
            self.reservationsPageState.locationId,
            profileId,
            self.reservationsPageState.meetingtypeId,
            self.reservationsPageState.month,
            self.reservationsPageState.year,
            self.reservationsPageState.statusId,
            self.reservationsPageState.page,
            self.reservationsPageState.itemsPerPage
          )
          .then((response) => {
            const linkSource = `data:application/csv;base64,${response.data}`
            const csvBlob = self.dataURItoBlob(linkSource)

            /**
             * Internet Explorer stuff!
             */
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(csvBlob, `Export.csv`)
              return
            }

            const url = window.URL.createObjectURL(csvBlob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Export.csv`)
            document.body.appendChild(link)
            link.click()

            link.remove()
            return response
          })
          .finally(() => {
            self.isLoadingCsv = false
          })
      }
    },

    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1])
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      // write the ArrayBuffer to a blob, and you're done
      const bb = new Blob([ab], { type: mimeString })
      return bb
    },

    getStatusName(statusId) {
      let name = ''

      switch (statusId) {
        case -1:
          name = 'Declined'
          break
        case 0:
          name = 'Pending'
          break
        case 1:
          name = 'Optional'
          break
        case 2:
          name = 'Final'
          break
        case 3:
          name = 'Cancelled'
          break
      }

      return name
    },

    getLocations() {
      let self = this

      if (!this.isLoadingLocations) {
        self.isLoadingLocations = true

        let searchTerm = ''
        let page = 0
        let itemsPerPage = 0
        let countryId = 0

        locationProvider.methods
          .getLocations(
            self.channelId,
            searchTerm,
            page,
            itemsPerPage,
            -2,
            -1,
            countryId
          )
          .then((response) => {
            self.locations = response.data.Results
          })
          .finally(() => {
            self.isLoadingLocations = false
          })
      }
    },

    goToReservation(reservation) {
      this.$router.push({
        name: `reservations-detail`,
        params: { channelId: this.channelId, reservationId: reservation.Id },
      })
    },

    beforeEnter: function (el) {
      el.style.opacity = 0
    },
    enter: function (el, done) {
      var delay = el.dataset.index * 50
      setTimeout(function () {
        el.style.transition = 'opacity 0.3s'
        el.style.opacity = '1'
      }, delay)
    },
    leave: function (el, done) {
      var delay = el.dataset.index * 50
      setTimeout(function () {
        el.style.transition = 'opacity 0.3s'
        el.style.opacity = '0'
      }, delay)
    },
    afterLeave: function (el) {
      if (el) {
        el.parentNode.removeChild(el)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.search-wrapper {
  justify-content: flex-start;
  margin: -7.5px;
  flex-wrap: wrap;

  .column {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 7.5px;
  }

  .column-status {
    select {
      min-width: 140px;
    }
  }
}
</style>
